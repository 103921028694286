.card {
  background-color: #fff;
  .clamp {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 2.2rem;
  }
  .location {
    font-size: 0.9rem;
  }
  .others {
    font-size: 0.7rem;
  }
  .timer {
    font-size: 0.8rem;
  }
}

.card:hover {
  background-color: #f2f2f2;
  transition: all 0.3ms ease;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  // color: white;
  // .text-black {
  //   color: white !important;
  // }
  // .text-primary {
  //   color: rgb(231, 231, 231) !important;
  // }
  // .text-dark {
  //   color: rgb(232, 232, 232) !important;
  // }
  // .text-secondary {
  //   color: white !important;
  // }
}
